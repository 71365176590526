import React from 'react';
import SelectField from './SelectField';

export type BaseProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    ref?: React.Ref<HTMLSelectElement>;
    error?: string;
  },
  HTMLSelectElement
>;

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement> &
    BaseProps & {
      id: string;
      label: string;
      wrapperClassName?: string;
    },
  HTMLSelectElement
>;

const STATE_OPTIONS = [
  { key: 'AC', value: 'Acre' },
  { key: 'AL', value: 'Alagoas' },
  { key: 'AP', value: 'Amapá' },
  { key: 'AM', value: 'Amazonas' },
  { key: 'BA', value: 'Bahia' },
  { key: 'CE', value: 'Ceará' },
  { key: 'DF', value: 'Distrito Federal' },
  { key: 'ES', value: 'Espirito Santo' },
  { key: 'GO', value: 'Goiás' },
  { key: 'MA', value: 'Maranhão' },
  { key: 'MT', value: 'Mato Grosso' },
  { key: 'MS', value: 'Mato Grosso do Sul' },
  { key: 'MG', value: 'Minas Gerais' },
  { key: 'PA', value: 'Pará' },
  { key: 'PB', value: 'Paraíba' },
  { key: 'PR', value: 'Paraná' },
  { key: 'PE', value: 'Pernambuco' },
  { key: 'PI', value: 'Piauí' },
  { key: 'RR', value: 'Roraima' },
  { key: 'RO', value: 'Rondônia' },
  { key: 'RJ', value: 'Rio de Janeiro' },
  { key: 'RN', value: 'Rio Grande do Norte' },
  { key: 'RS', value: 'Rio Grande do Sul' },
  { key: 'SC', value: 'Santa Catarina' },
  { key: 'SP', value: 'São Paulo' },
  { key: 'SE', value: 'Sergipe' },
  { key: 'TO', value: 'Tocantins' },
];

const EMPTY_OPTION = { key: '', value: 'Escolha um Estado' };

export const CountryStateSelectField = React.forwardRef<HTMLSelectElement, Props>(
  ({ ...props }, ref) => (
    <SelectField ref={ref} {...props} options={[EMPTY_OPTION, ...STATE_OPTIONS]} />
  )
);

export default CountryStateSelectField;
