import { InternalUser } from 'internalUsers/models/internalUser';
import UsersTableItem from './UsersTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  users: InternalUser[];
  disableAdminToggle: boolean;
  onToggleAdmin: (id: number, value: boolean) => void;
  onToggleBlockedStatus: (id: number) => void;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function UsersTable({
  users,
  disableAdminToggle,
  onClickEditButton,
  onClickRemoveButton,
  onToggleAdmin,
  onToggleBlockedStatus,
}: Props) {
  return (
    <>
      <Table>
        <TableHead>
          <TableHeadColumn>Nome</TableHeadColumn>
          <TableHeadColumn>E-mail</TableHeadColumn>
          <TableHeadColumn className="text-center">Administrador</TableHeadColumn>
          <TableHeadColumn className="text-center">Bloqueado</TableHeadColumn>
          <TableHeadColumn className="text-right">Ações</TableHeadColumn>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <UsersTableItem
              key={user.id}
              user={user}
              disableAdminToggle={disableAdminToggle}
              onToggleBlock={onToggleBlockedStatus}
              onToggleAdmin={onToggleAdmin}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}
