import { Switch } from '@headlessui/react';
import classNames from 'classnames';

type Props = {
  value: boolean;
  disabled?: boolean;
  label: string;
  onChange: (value: boolean) => void;
};

export default function ToggleButton({ disabled, value, label, onChange }: Props) {
  return (
    <Switch
      checked={value}
      disabled={disabled}
      onChange={onChange}
      role="switch"
      className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none"
    >
      <span className="sr-only">{label}</span>
      <span
        aria-hidden="true"
        className="pointer-events-none absolute w-full h-full rounded-md"
      ></span>
      <span
        aria-hidden="true"
        className={classNames(
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
          {
            'bg-primary': value,
            'bg-gray-200': !value,
          }
        )}
      ></span>
      <span
        aria-hidden="true"
        className={classNames(
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
          {
            'translate-x-5': value,
            'translate-x-0': !value,
          }
        )}
      />
    </Switch>
  );
}
