import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
  HTMLInputElement
>;

export const PasswordInput = React.forwardRef<HTMLInputElement, Props>(
  ({ autoComplete = 'current-password', ...props }, ref) => {
    return (
      <BaseInput type="password" ref={ref} autoComplete={autoComplete} {...props} />
    );
  }
);

export default PasswordInput;
