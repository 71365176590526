import { Link } from 'react-router-dom';

export default function SidebarLogo() {
  return (
    <Link
      to="/dashboard"
      className="flex-shrink-0 flex items-center text-white hover:text-primary"
      title="IntraNEX"
    >
      <svg
        className="h-8 w-auto"
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.34886 10.2008L0.244636 0H4.81989C5.11695 0 5.33538 0.040307 5.47517 0.124022C5.61496 0.204636 5.74019 0.34106 5.84794 0.533294L9.77376 7.60874C9.81744 7.48472 9.86987 7.3638 9.92811 7.24908C9.98636 7.13436 10.0533 7.01344 10.1232 6.88941L13.5598 0.607707C13.784 0.204636 14.0927 0.00310003 14.4801 0.00310003H18.866L12.6803 9.98996L19 21H14.4247C14.1277 21 13.886 20.9225 13.6996 20.7706C13.5132 20.6155 13.3676 20.4357 13.2598 20.2218L9.30779 12.8735C9.23498 13.0843 9.15343 13.2672 9.06315 13.4223L5.3878 20.2156C5.28005 20.4171 5.13443 20.5969 4.95386 20.7551C4.7733 20.9132 4.54905 20.9938 4.2782 20.9938H0L6.34886 10.2008Z"
          fill="currentColor"
        />
      </svg>
    </Link>
  );
}
