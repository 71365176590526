import { FormContainer } from 'ui';
import TextField from 'ui/form/TextField';
import { FieldError, UseFormRegister } from 'react-hook-form';

export type FormFields = {
  powerDistributionUnitLegalName: string;
  powerDistributionUnitDocumentId: string;
  powerDistributionUnitCountryState: string;
};

type Field =
  | 'powerDistributionUnitLegalName'
  | 'powerDistributionUnitCountryState'
  | 'powerDistributionUnitDocumentId';

export const FORM_FIELDS: Field[] = [
  'powerDistributionUnitLegalName',
  'powerDistributionUnitCountryState',
  'powerDistributionUnitDocumentId',
];

export type FormErrors = {
  powerDistributionUnitLegalName?: FieldError | undefined;
  powerDistributionUnitDocumentId?: FieldError | undefined;
  powerDistributionUnitCountryState?: FieldError | undefined;
};

const COUNTRY_STATES = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espirito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RR: 'Roraima',
  RO: 'Rondônia',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export default function UserFormFields({
  errors,
  register,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
}) {
  const errorCountryState = errors.powerDistributionUnitCountryState?.message;

  return (
    <FormContainer>
      <TextField
        error={errors.powerDistributionUnitLegalName?.message}
        label="Razão Social"
        id="powerDistributionUnitLegalName"
        {...register('powerDistributionUnitLegalName', { required: true })}
        placeholder="COPEL - Companhia Paranaense de..."
      />

      <fieldset>
        <label
          htmlFor="powerDistributionUnitCountryState"
          className="block text-sm font-medium text-gray-dark400 mb-3"
        >
          Estado
        </label>
        <select
          id="powerDistributionUnitCountryState"
          className="mt-2 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500"
          {...register('powerDistributionUnitCountryState', {
            required: true,
          })}
        >
          {Object.entries({ '': 'Seu estado', ...COUNTRY_STATES }).map(
            ([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            )
          )}
        </select>
        {errorCountryState && (
          <p className="text-red-500 text-xs">{errorCountryState}</p>
        )}
      </fieldset>

      <TextField
        error={errors.powerDistributionUnitDocumentId?.message}
        label="Documento"
        id="powerDistributionUnitDocumentId"
        {...register('powerDistributionUnitDocumentId', { required: true })}
        placeholder="Ex.: 28.054.303/0001-06"
      />
    </FormContainer>
  );
}
