import { FormContainer } from 'ui';
import CheckboxField from 'ui/form/CheckboxField';
import EmailField from 'ui/form/EmailField';
import TextField from 'ui/form/TextField';
import { FieldError, UseFormRegister } from 'react-hook-form';

type Field = 'userName' | 'userEmail' | 'userIsAdmin';

export const FORM_FIELDS: Field[] = ['userName', 'userEmail', 'userIsAdmin'];
export type FormFields = {
  userName: string;
  userEmail: string;
  userIsAdmin: boolean;
};

export type FormErrors = {
  userName?: FieldError | undefined;
  userEmail?: FieldError | undefined;
  userIsAdmin?: FieldError | undefined;
};

export default function UserFormFields({
  disable,
  errors,
  register,
}: {
  disable?: {
    userEmail?: boolean;
  };
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
}) {
  return (
    <FormContainer>
      <CheckboxField
        error={errors.userIsAdmin?.message}
        label="O usuário é admin?"
        {...register('userIsAdmin', { required: false })}
        wrapperClassName="col-span-3 mb-4"
        inputLabel="Admin"
      />
      <TextField
        error={errors.userName?.message}
        label="Nome"
        id="userName"
        {...register('userName', { required: true })}
        placeholder="Ex.: João da Silva"
      />
      <EmailField
        disabled={disable?.userEmail}
        error={errors.userEmail?.message}
        label="Email"
        {...register('userEmail', { required: true })}
        placeholder="exemplo@email.com"
      />
    </FormContainer>
  );
}
