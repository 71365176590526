import { Cooperative } from 'cooperatives/models/cooperative';
import CooperativesTableItem from './CooperativesTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  cooperatives: Cooperative[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function CooperativesTable({
  cooperatives,
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Nome</TableHeadColumn>
        <TableHeadColumn className="text-center">UF</TableHeadColumn>
        <TableHeadColumn>Documento</TableHeadColumn>
        <TableHeadColumn className="text-center">Tipo</TableHeadColumn>
        <TableHeadColumn className="text-center">Nome da matriz</TableHeadColumn>
        <TableHeadColumn>Valor da subscrição</TableHeadColumn>
        <TableHeadColumn className="text-right">Ações</TableHeadColumn>
      </TableHead>
      <TableBody>
        {cooperatives.map((cooperative: Cooperative) => (
          <CooperativesTableItem
            key={cooperative.id}
            cooperative={cooperative}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
            disableEditButton={disableEditButton}
            disableRemoveButton={disableRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
