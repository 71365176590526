type Props = {
  children: React.ReactNode;
};

export default function Table({ children }: Props) {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-dark700 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-dark700 text-sm">
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
