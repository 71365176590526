import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
  HTMLInputElement
>;

export const NumberInput = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }, ref) => <BaseInput type="number" ref={ref} {...props} />
);

export default NumberInput;
