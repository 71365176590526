import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
  HTMLInputElement
>;

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }, ref) => {
    return <BaseInput type="text" ref={ref} {...props} />;
  }
);

export default TextInput;
