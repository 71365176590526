import Search from './Search';
import AddButton from './AddButton';

type Props = {
  addButtonLabel: string;
  onClickAddButton: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function DashboardHeaderSearch({
  addButtonLabel,
  onClickAddButton,
  onSearchChange,
}: Props) {
  return (
    <header className="relative z-10 flex-shrink-0 flex h-16 bg-gray-dark600">
      <div className="flex-1 px-4 flex justify-between">
        <Search onChange={onSearchChange} />
        <div className="ml-4 flex items-center md:ml-6">
          <AddButton onClick={onClickAddButton} label={addButtonLabel} />
        </div>
      </div>
    </header>
  );
}
