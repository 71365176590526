import React from 'react';

export interface Option {
  key: string;
  value: string;
}

export type BaseProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    ref?: React.Ref<HTMLSelectElement>;
    error?: string;
  },
  HTMLSelectElement
>;

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement> & BaseProps & { options: Option[] },
  HTMLSelectElement
>;

export const SelectInput = React.forwardRef<HTMLSelectElement, Props>(
  ({ options, ...props }, ref) => (
    <select ref={ref} {...props}>
      {options.map(({ key, value }) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  )
);

export default SelectInput;
