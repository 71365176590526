import classNames from 'classnames';
import React from 'react';
import { EmailInput } from 'ui';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      label?: string;
    },
  HTMLInputElement
>;

export const EmailField = React.forwardRef<HTMLInputElement, Props>(
  ({ className, label = 'Email', id = 'email', ...props }, ref) => {
    return (
      <div>
        <label htmlFor={id} className="block text-xs font-bold text-white ml-2">
          {label}
        </label>
        <div className="mt-1">
          <EmailInput
            id={id}
            name="email"
            autoComplete="email"
            placeholder="lucas@nexenergy.com.br"
            className={classNames(
              'block w-full bg-gray-dark-34 border border-gray-dark-34 px-2 py-2 rounded shadow-sm text-xs text-gray-dark-a0 font-semibold placeholder-gray-dark-a0 focus:outline-none focus:ring-gray-dark-34 focus:border-gray-dark-34',
              className
            )}
            ref={ref}
            {...props}
          />
        </div>
      </div>
    );
  }
);

export default EmailField;
