import { gql } from '@apollo/client';
import { Cooperative } from 'cooperatives/models/cooperative';

export interface CooperativeCreateMutationInput {
  cooperativeHeadquarterId: number | null;
  cooperativeSubscriptionCostCents: number;
  cooperativeLegalName: string;
  cooperativeDocumentId: string;
  cooperativeAddressPostalCode: number;
  cooperativeAddressState: string;
  cooperativeAddressCity: string;
  cooperativeAddressStreet: string;
  cooperativeAddressComplement: string | null;
}

export interface CooperativeCreateMutationVariables {
  cooperativeCreateInput: CooperativeCreateMutationInput;
}

export interface CooperativeCreated {
  cooperativeCreate: Cooperative;
}

export const COOPERATIVE_CREATE_MUTATION = gql`
  mutation cooperativeCreate($cooperativeCreateInput: CooperativeCreateInput!) {
    cooperativeCreate(input: $cooperativeCreateInput) {
      id
      cooperativeLegalName
      cooperativeAddressState
      cooperativeSubscriptionCostCents
      cooperativeDocumentId
      cooperativeDeletedAt
      cooperativeHeadquarter {
        cooperativeLegalName
      }
    }
  }
`;
