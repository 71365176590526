import { MdSearch } from 'react-icons/md';
import { SearchInput } from 'ui';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
};

export default function Search({
  label = 'Busca',
  placeholder = 'Buscar',
  onChange,
}: Props) {
  return (
    <section className="flex-1 flex" role="search">
      <form className="w-full flex md:ml-0" action="#" method="GET">
        <label htmlFor="search-field" className="sr-only">
          {label}
        </label>
        <div className="relative w-full text-gray-dark500 focus-within:text-gray-dark400">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <MdSearch className="h-5 w-5" aria-hidden="true" />
          </div>
          <SearchInput
            id="search-field"
            className="block w-full h-full pl-8 pr-3 py-2 border-transparent bg-gray-dark600 placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500 focus:outline-none focus:ring-transparent focus:border-transparent sm:text-sm"
            placeholder={placeholder}
            name="search"
            aria-label="Buscar no conteúdo abaixo"
            autoComplete="off"
            onChange={onChange}
          />
        </div>
      </form>
    </section>
  );
}
