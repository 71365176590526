import { gql } from '@apollo/client';

interface CooperativeSelectConnection {
  entries: {
    id: number;
    cooperativeLegalName: string;
  }[];
}

export interface CooperativesSelectList {
  cooperatives: CooperativeSelectConnection;
}

export const cooperativesTypename = 'CooperativeConnection';

const MAX_PAGE_SIZE = 100;

export const COOPERATIVES_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const COOPERATIVES_SELECT_QUERY = gql`
  query cooperatives($after: String, $before: String, $pageSize: Int) {
    cooperatives(after: $after, before: $before, pageSize: $pageSize) {
      entries {
        id
        cooperativeLegalName
      }
    }
  }
`;
