import classNames from 'classnames';
import React from 'react';
import { Option } from 'ui/components/form/SelectInput';
import { SelectInput } from 'ui';

export type BaseProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    ref?: React.Ref<HTMLSelectElement>;
    error?: string;
  },
  HTMLSelectElement
>;

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement> &
    BaseProps & {
      id: string;
      label: string;
      wrapperClassName?: string;
      options: Option[];
    },
  HTMLSelectElement
>;

export const SelectField = React.forwardRef<HTMLSelectElement, Props>(
  ({ className, wrapperClassName, label, id, ...props }, ref) => {
    return (
      <fieldset className={wrapperClassName}>
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-dark400 mb-3"
        >
          {label}
        </label>
        <SelectInput
          id={id}
          className={classNames(
            'mt-2 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500',
            className
          )}
          ref={ref}
          {...props}
        />
      </fieldset>
    );
  }
);

export default SelectField;
