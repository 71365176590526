import LoginBackground from 'auth/assets/login-background.jpeg';
import Logo from 'auth/assets/logo.svg';
import { useLoading } from 'ui/contexts/overlay/Loading';

type Props = {
  children: React.ReactNode;
};

export default function Login({ children }: Props) {
  const { LoadingOverlay } = useLoading();

  return (
    <>
      <div className="relative min-h-screen bg-black">
        <div className="absolute inset-0">
          <img className="h-full w-full object-cover" src={LoginBackground} alt="" />
        </div>
        <div className="relative">
          <div className="min-h-screen flex flex-col justify-center align-center gap-y-10">
            <div className="mx-auto w-full max-w-md">
              <img className="mx-auto h-14 w-auto" src={Logo} alt="IntraNEX" />
            </div>

            <div className="relative mx-auto w-full max-w-md shadow border border-gray-dark-1e rounded-lg overflow-hidden">
              <div className="absolute inset-0 bg-gray-dark200 mix-blend-multiply" />

              <div className="relative py-12 px-10">{children}</div>
            </div>
          </div>
        </div>
      </div>

      <LoadingOverlay />
    </>
  );
}
