import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
  HTMLInputElement
>;

export const EmailInput = React.forwardRef<HTMLInputElement, Props>(
  ({ autoComplete = 'email', ...props }, ref) => {
    return (
      <BaseInput type="email" ref={ref} autoComplete={autoComplete} {...props} />
    );
  }
);

export default EmailInput;
