import React from 'react';
import ReactDatePicker, {
  DatePickerProps,
} from 'react-date-picker/dist/entry.nostyle';
import './DatePicker.scss';
import './Calendar.scss';

export type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    Omit<DatePickerProps, 'inputRef'> & {
      wrapperClassName?: string;
      ref?: React.Ref<HTMLInputElement>;
      error?: string;
    },
  HTMLInputElement
>;

export const DatePicker = React.forwardRef<HTMLInputElement, Props>(
  ({ wrapperClassName, className, selectRange, error, ...props }, ref) => {
    const [value, onChange] = React.useState(new Date());

    return (
      <div className={wrapperClassName}>
        <ReactDatePicker
          selectRange={selectRange}
          {...props}
          // TODO: fix types to allow ref forwarding
          // inputRef={ref}
          className={className}
          onChange={onChange}
          value={value}
          showNeighboringMonth={false}
          showDoubleView
          showLeadingZeros
        />
        {error && <p className="text-red-500 mt-1 text-xs">{error}</p>}
      </div>
    );
  }
);

export default DatePicker;
