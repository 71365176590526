import classNames from 'classnames';
import React from 'react';
import { EmailInput } from 'ui';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      label?: string;
      wrapperClassName?: string;
    },
  HTMLInputElement
>;

export const EmailField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled,
      className,
      wrapperClassName,
      autoComplete = 'email',
      label = 'Email',
      id = 'email',
      ...props
    },
    ref
  ) => {
    return (
      <fieldset className={wrapperClassName}>
        <label
          htmlFor={id}
          className={classNames('block text-sm font-medium text-gray-dark400 mb-3', {
            'text-gray-dark600': disabled,
          })}
        >
          {label}
        </label>
        <EmailInput
          id={id}
          disabled={disabled}
          name="email"
          autoComplete={autoComplete}
          className={classNames(
            'mt-2 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500',
            className,
            {
              'bg-gray-dark700 border-gray-dark600 hover:bg-gray-dark700 hover:border-gray-dark600 placeholder-gray-dark600 !text-gray-dark600':
                disabled,
            }
          )}
          ref={ref}
          {...props}
        />
      </fieldset>
    );
  }
);

export default EmailField;
