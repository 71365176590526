import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      ['aria-label']: string;
    },
  HTMLInputElement
>;

export const SearchInput = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }, ref) => {
    return <BaseInput type="search" ref={ref} {...props} />;
  }
);

export default SearchInput;
