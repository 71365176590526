import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Dashboard,
  DashboardHeaderSearch,
  DashboardMainHeaderTitle,
} from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import PowerDistributionUnitsTable from 'powerDistributionUnits/components/table/PowerDistributionUnitsTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import { POWER_DISTRIBUTION_UNIT_LIST_QUERY } from 'powerDistributionUnits/graphql/powerDistributionUnitListQuery';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { useHistory } from 'react-router';

type State = {
  powerDistributionUnits: PowerDistributionUnit[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Concessionárias',
};

const DASHBOARD_POWER_DISTRIBUTION_UNITS_CREATE_ROUTE =
  '/dashboard/power-distribution-units/create';

export default function PowerDistributionUnitsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [search, setSearch] = useState('');
  const [state, setState] = useState<State>({
    powerDistributionUnits: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [powerDistributionUnitsList, { data, loading, error, refetch }] =
    useLazyQuery(POWER_DISTRIBUTION_UNIT_LIST_QUERY, INITIAL_QUERY_STATE_CONFIG);

  useEffect(() => {
    powerDistributionUnitsList();
  }, [powerDistributionUnitsList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  useEffect(() => {
    if (loading) {
      showLoading();
      return;
    }

    if (data) {
      setState({
        powerDistributionUnits: data.powerDistributionUnits.entries,
        hasNextPage: !!data.powerDistributionUnits.afterCursor,
        hasPreviousPage: !!data.powerDistributionUnits.beforeCursor,
      });
    }

    closeLoading();
  }, [addToast, closeLoading, data, loading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data.powerDistributionUnits.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.powerDistributionUnits.beforeCursor,
      });
  };

  const onClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    console.log('onClickEditButton');
  };

  const onClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    showConfirm();
  };

  const onConfirm = () => {
    closeConfirm();
  };

  const onCancel = () => {
    closeConfirm();
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    console.log({ search });
  };

  const onClickAddButton = () =>
    push(DASHBOARD_POWER_DISTRIBUTION_UNITS_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardHeader={
        <DashboardHeaderSearch
          addButtonLabel="Concessionária"
          onClickAddButton={onClickAddButton}
          onSearchChange={handleSearch}
        />
      }
      dashboardMainHeaderTitle={
        <DashboardMainHeaderTitle title="Concessionárias de Energia" />
      }
    >
      <div className="rounded-lg">
        <PowerDistributionUnitsTable
          powerDistributionUnits={state.powerDistributionUnits}
          onClickEditButton={onClickEditButton}
          onClickRemoveButton={onClickRemoveButton}
        />
        <Pagination
          onNextClick={handleClickNext}
          onPreviousClick={handleClickBefore}
          disableNext={!state.hasNextPage}
          disableBefore={!state.hasPreviousPage}
        />
        <ConfirmOverlay
          title="Você tem certeza que deseja realizar essa ação?"
          text=""
          variant="primary"
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
