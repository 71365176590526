import { MenuSidebar } from 'dashboard/models/navigation';
import {
  MdAccountBalance,
  MdHome,
  MdGroup,
  MdShowChart,
  MdLocalAtm,
  MdArrowBack,
  MdArrowForward,
  MdMoneyOff,
  MdFlashOn,
  MdPoll,
  MdStoreMallDirectory,
  MdWifiTethering,
} from 'react-icons/md';

export const menuSidebar: MenuSidebar = [
  { name: 'Início', subtitle: 'INÍCIO', to: '/dashboard', icon: MdHome },
  {
    name: 'Gestão de Concessionária',
    subtitle: 'CONCESSIONÁRIA',
    icon: MdAccountBalance,
    submenu: [
      {
        name: 'Concessionárias',
        to: '/dashboard/power-distribution-units',
        icon: MdAccountBalance,
      },
      { name: 'PIS/COFINS', to: '/dashboard', icon: MdShowChart },
      { name: 'Grupo tarifário', to: '/dashboard', icon: MdLocalAtm },
    ],
  },
  {
    name: 'Gestão de Usuários',
    subtitle: 'GESTÃO DE USUÁRIOS',
    to: '/dashboard',
    icon: MdGroup,
    submenu: [
      {
        name: 'Usuário Interno',
        to: '/dashboard/internal-users',
        icon: MdArrowBack,
      },
      { name: 'Usuário Final', to: '/dashboard', icon: MdArrowForward },
    ],
  },
  {
    name: 'Gestão de Impostos',
    subtitle: 'TAXES & FEES MANAGEMENT',
    to: '/dashboard/admins',
    icon: MdGroup,
    submenu: [{ name: 'ICMS', to: '/dashboard', icon: MdMoneyOff }],
  },
  {
    name: 'Cooperativas',
    subtitle: 'COOPERATIVAS',
    to: '/dashboard/cooperatives',
    icon: MdFlashOn,
  },
  {
    name: 'Usinas',
    subtitle: 'USINAS',
    to: '/dashboard',
    icon: MdWifiTethering,
  },
  {
    name: 'UCs',
    subtitle: 'UCs',
    to: '/dashboard',
    icon: MdStoreMallDirectory,
  },
  {
    name: 'Contabilidade',
    subtitle: 'CONTABILIDADE',
    to: '/dashboard',
    icon: MdPoll,
  },
];
