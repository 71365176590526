import { Button } from 'ui';
import PasswordField from './PasswordField';
import { useLoading } from 'ui/contexts/overlay/Loading';

export default function NewPasswordForm() {
  const { showLoading } = useLoading();

  const handleSubmitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    showLoading();

    console.log('handleSubmitForm');
  };

  return (
    <form onSubmit={handleSubmitForm} className="flex flex-col gap-y-5">
      <PasswordField
        autoComplete="new-password"
        id="new-password"
        label="Nova senha"
      />

      <PasswordField
        autoComplete="new-password"
        id="new-password"
        label="Confirmar nova senha"
      />

      <div className="text-center">
        <Button className="uppercase" type="submit">
          Salvar
        </Button>
      </div>
    </form>
  );
}
