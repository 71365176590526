import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function TableBodyColumn({ children, className }: Props) {
  return (
    <td className={classNames('px-6 py-4 whitespace-nowrap', className)}>
      {children}
    </td>
  );
}
