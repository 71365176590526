import { useLocation } from 'react-router-dom';
import NavigationItem from './NavigationItem';
import { menuSidebar } from 'dashboard/utils/navigation';

export default function Navigation() {
  let location = useLocation();

  if (!menuSidebar) {
    return null;
  }

  const isCurrentPage = (page: string) => location.pathname === page;

  return (
    <ul className="flex-1 mt-6 w-full px-2 space-y-1">
      {menuSidebar.map((item) => (
        <li key={item.name}>
          <NavigationItem
            name={item.name}
            subtitle={item.subtitle}
            icon={<item.icon className="h-6 w-6" aria-hidden="true" />}
            isCurrentPage={isCurrentPage(item.name)}
            submenu={item.submenu}
          />
        </li>
      ))}
    </ul>
  );
}
